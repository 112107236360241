export const url    = process.env.REACT_APP_URL
export const urlApi = process.env.REACT_APP_URLAPI







            
