import React, {useEffect} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigate } from "react-router-dom";

const Main = () => {
    let navigate = useNavigate();

    useEffect(() => {

        const first = async() => {
            let _user    = await AsyncStorage.getItem('@user');

            if(_user !== null && _user !== 'null' && _user !== undefined && _user !== 'undefined'){
                return navigate("/directories");
            }else{
                return navigate("/login");
            }
        }
        setTimeout(() => {first()}, 1000);
    }, [])
    
    return (
        <>
            <div className='text-center'>
                <div className='w-100 vh-100 m-auto'>
                    <div className='d-flex h-100 justify-content-center align-items-center'>
                        <div className='animate__animated animate__zoomIn animate__slower'>
                            <img className="mb-4" src="https://labeldictate.com/assets/img/Label-Dictate-Logo-V2021.png" alt="" width={200} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main