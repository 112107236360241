import React, {useState, useEffect} from 'react'
import {
    NavLink
  } from "react-router-dom";
const NotFound = ({noTimeInterval = false}) => {

    const [contador, setcontador] = useState(false);
    
    useEffect(() => {
      
        first()

    }, []);

    const first = async () => {
    
        if(!noTimeInterval){
            setTimeout(() => {
                // return () => {
                    setcontador(true);
                // }
            }, 3000);
        }else{
            // return () => {
                setcontador(true);
            // }
        }
        
    }
  return (
    <>
    {
        contador 
        ?   <div id='oopss'>
                <div className='container animate__animated animate__fadeIn'>
                    <div id='error-text'>
                        <img className='nf-img' src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg " alt="404" />
                        <h1 className='display-1'>Page not Found 404</h1>
                        <p className="fs-5">This page does not exist! Either your address has been deleted or changed.</p>
                        <p className="fs-5">You can be directed to the home page...</p>
                        <NavLink to="/" className="back">Go Home </NavLink>
                    </div>
                </div>
            </div>
            
        :   <div id='oopss'>
                <div className='container h-100'>
                    <div className='d-flex justify-content-center h-100'>
                        <div className="spinner-grow m-auto text-primary" role="status" style={{width: "20rem", height: "20rem"}}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>

                </div>
            </div>


    }
    </>
  )
}

export default NotFound