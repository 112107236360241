import React from 'react'
import UserState from './userContext/UserState';
import './App.css';
import "animate.css";
import {
	BrowserRouter,
	Routes,
	Route
  } from "react-router-dom";
import Header from './views/layouts/Header';
import Main from './views/main/Main';
import Login from './views/users/Login';
import Dir from './views/dirs/Dir';
import Templates from './views/templates/Templates';
import Softwares from './views/softwares/Softwares';
import NotFound from './views/layouts/NotFound';
import Wrapper from './views/layouts/Wrapper';

function App() {

	return (
		<>
			<BrowserRouter>
				<UserState>
					<Header/>
					<Wrapper>
						<Routes>
							<Route path="/" element={ <Main/>}/>
							<Route path="/login" element={ <Login/>} />
							<Route path="/directories" element={ <Softwares/> } />
							<Route path="/directories/:name" element={ <Dir/> } />
							<Route path="/directories/:name/:dir" element={ <Templates/> } />
							<Route path="*" element={<NotFound noTimeInterval={true}/>} /> 
						</Routes>
					</Wrapper>
				</UserState>
			</BrowserRouter>
		</>
	);
}

export default App;
