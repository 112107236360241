import React, {useState, useEffect} from 'react'
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from 'axios';
import { url } from '../../global/Global';
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form } from 'formik';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt,faCircleLeft } from '@fortawesome/free-solid-svg-icons';

const Templates = () => {
	const [bar, setbar]             = useState('progress-bar progress-bar-striped progress-bar-animated');
	const [templates, settemplates] = useState([]);

    const [_loaded, _setloaded]   = useState(0);
    const [_total, _settotal]     = useState(0);
    const [_percent, _setpercent] = useState(0);

    const { dir, name } = useParams();
    const navigate      = useNavigate();

    const [verifyStatus, setverifyStatus]   = useState(false);
    const [verifyMessage, setverifyMessage] = useState('');
    const [loading, setloading]             = useState(false);
    const [firstloading, setfirstloading]   = useState(false);
/*******************************************************************************/
	const handlecsv = (e) => {
        const _extension = [];
        let   _formData  = new FormData();
        for(const element of e.target.files){
            _formData.append('files', element);

            const _extension_ = element.name.substring(element.name.lastIndexOf('.')+1, element.name.length) || element.name;
            _extension.push(_extension_);

        }
        _formData.append('dir', dir);
        _formData.append('name', name);
        checkCatalog(_formData, _extension);
    }
/*******************************************************************************/
	const first = async() => { 			
		// const log_json = await JSON.parse(user);
		let   log_json = await AsyncStorage.getItem('@user');
		const token    = `Bearer ${log_json.replace(/^["'](.+(?=["']$))["']$/, '$1')}`;

		const headers = {
			'Authorization': token,
			'Content-Type': 'application/json',
			'X-Requested-With': 'XMLHttpRequest',
			'Access-Control-Allow-Origin': '*'
		}

		await axios.get(`${url}templates/frt/${name}/${dir}`, {headers: headers})
			.then(async (response) => {
				console.log(response.data);
				if(response.data.code === 200 && response.data.status === 'success'){
					settemplates(response.data.message);
					console.log(response.data.message);
				}else if(response.data.code === 200 && response.data.status === 'warning'){
					setverifyStatus(true);
					setverifyMessage(response.data.message);

				}else if(response.data.code === 200 && response.data.status === 'info'){
					// setverifyStatus(true);
					setverifyMessage(response.data.message);

				}
                else{
					Swal.fire({
						icon : 'error',
						title: 'Error!',
						text : response.data.message,
					});
				}
			}).catch(err =>{
				console.log('err');
				console.log(err);
			}).then(()=>{
				setfirstloading(true)
			});
	}

/*******************************************************************************/
    const extensions = () => {
        document.getElementById("formFileSm").value = "";
            Swal.fire({
                icon: 'info',
                title: 'Invalid Extensions',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
        });
        return false;
    }
/*******************************************************************************/
    const checkCatalog = async (formData, extension) => {
    // const checkCatalog = async (formData) => {
        console.log(formData);
        console.log(extension);
        console.log(document.getElementById("formFileSm").files.length);
        // return;
        if (document.getElementById("formFileSm").files.length===0) {
            document.getElementById("formFileSm").value = "";
            Swal.fire({
                icon: 'info',
                title: 'Invalid Files',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            return;
        }else{
            let x = true;
            extension.forEach(element => {
                if(element !== 'ttb' && element !== 'pdl'){
                    x = extensions();
                }
            });
            if(!x){
                return false;
            }
        }
        setloading(true);
        setbar('progress-bar progress-bar-striped progress-bar-animated');
        setTimeout(() => {}, 400);

        const onUploadProgress = (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor( (loaded*100) / total);
            _setloaded(loaded);
            _settotal(total);
            _setpercent(percent);
            console.log( `${loaded}kb of ${total}kb | ${percent}%` );
        }

		let   log_json = await AsyncStorage.getItem('@user');
		const token    = `Bearer ${log_json.replace(/^["'](.+(?=["']$))["']$/, '$1')}`;
		const headers  = {
			'Authorization': token,
			// 'Content-Type': 'application/json',
			'Content-Type': 'multipart/form-data',
			'X-Requested-With': 'XMLHttpRequest',
		}
        console.log(headers);
        console.log(url+"templates");
        // await axios.post(`${url}templates`, formData, options, {headers: headers})
        await axios.post(`${url}templates`, formData, {headers: headers, onUploadProgress: onUploadProgress})
          .then((response) => {
               
                if(response.data.status ==='success'){
                    setbar('progress-bar bg-success progress-bar-striped animate__animated animate__fadeOut animate__delay-3s');
                    settemplates(response.data.message);
                    setverifyMessage('');
                    Swal.fire({
                        icon : 'success',
                        title: 'Success',
                        text : 'Success upload',
                    });
                    setTimeout(() => {
                        setloading(false);
                    }, 7000);
                    
                }else if(response.data.message ==='extension'){
                    extensions();
                    setbar('progress-bar bg-danger progress-bar-striped');
                    setloading(false);
                }else{
                    setbar('progress-bar bg-danger progress-bar-striped');
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: response.data.message,
                    });
                    setloading(false);
                }
                console.log(response.data);
            }).catch((error) => {
                
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: error,
                });
                setloading(false);
                console.log(error);
            });
    }
/*******************************************************************************/

    const deleteTemplate = (params) => {

        Swal.fire({
            title: 'Delete template?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
        if (result.isConfirmed) {
            let   log_json = await AsyncStorage.getItem('@user');
            const token    = `Bearer ${log_json.replace(/^["'](.+(?=["']$))["']$/, '$1')}`;

            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Access-Control-Allow-Origin': '*'
            }

            await axios.delete(`${url}templates/${params}`, {headers: headers})
                .then(async (response) => {
                    console.log(response.data);
                    if(response.data.code === 200 && response.data.status === 'success'){
                        console.log(response.data.message);
                        settemplates(response.data.message)
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: response.data.message,
                        });
                    }
                }).catch(err =>{
                    console.log('err');
                    console.log(err);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: err,
                    });
                });                
            }
        });
        console.log(params);
    }

/*******************************************************************************/
	useEffect(() => {
        window.scrollTo(0,0);
		first();
	}, [])
/*******************************************************************************/
  return (
    !firstloading 
        ?<div className='container text-center vh-100'>
            <div className='d-flex flex-column align-items-center justify-content-center h-100 animate__animated animate__fadeIn'>
                <div className="spinner-grow" style={{width: "5rem", height: "5rem", marginBottom: "6rem", color: "#6ea8fe"}} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>

            </div>
        </div>

        :
        
        verifyStatus 
        ?<>
            <div className='w-100 border-top border-bottom px-5 alert alert-primary'>
                <div className='row animate__animated animate__fadeIn'>
                    <div className='col-md-2'>
                        <div className="d-flex px-2 align-items-center pointer-f pointer-f-directory rounded" onClick={() => navigate(-1)}>
                            <FontAwesomeIcon icon={faCircleLeft}/>
                            <p className='ms-2 m-0'>Go back</p>
                        </div>
                    </div>
                    <div className="col-md-7"></div>
                </div>
            </div>
            <div className='container mt-5 text-center'>
                <h2>{verifyMessage}</h2>
            </div>
        </>
        :<div className='bg-light h-100 vh-100  animate__animated animate__fadeIn'>
            <div className='w-100 border-top border-bottom px-5 alert alert-primary'>
                <div className='row'>
                    <div className='col-md-2'>
                        <div className="d-flex px-2 align-items-center pointer-f pointer-f-directory rounded" onClick={() => navigate(-1)}>
                            <FontAwesomeIcon icon={faCircleLeft}/>
                            <p className='ms-2 m-0'>Go back</p>
                        </div>
                    </div>
                    <div className="col-md-7"></div>
                </div>
            </div>

            <div className='container bg-light py-5'>
                <div className="row">
                    <div className='col-md-12 mb-3'> 
                        <div className="card">
                            <div className="card-header">
                                <h3 className='fw-bold text-uppercase text-center'>{dir}</h3>
                            </div>
                            <div className='card-body'>
                                <h4 className='text-center'>{verifyMessage}</h4>
                                <div className="row">
                                    <div className="col-md-4">
                                        <ul className="list-group list-group-flush">							
                                        {
                                        templates.length > 0 &&
                                            templates.map((e,i) => {
                                                return <li 
                                                        key={i} 
                                                        className="list-group-item">
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <p className='m-0'>{i+1}.- {e.name}</p>
                                                            <button className='btn btn-danger btn-sm rounded-circle' type='button' onClick={() => deleteTemplate(e._id)}> 
                                                                <FontAwesomeIcon icon={faTrashAlt} color='white' />
                                                            </button>
                                                        </div>   
                                                    </li>                                                      
                                                })
                                        }	
                                        </ul>				
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-5">
                            <div className="card-header">
                                <h5 className='fw-bold text-uppercase text-center'>Upload file </h5>
                            </div>
                            <div className='card-body'>
                                <Formik
                                    // enableReinitialize
                                    initialValues={{
                                        // observaciones: observaciones
                                    }}
                                    onSubmit={(values, actions) => checkCatalog(values, actions)}
                                >
                                    <Form>
                                        <div className="row">
                                            {/* <label htmlFor="formFile" className="form-label">Selecciona un catalogo desde tu dispositivo en formato <b>CSV </b>o<b> XLS</b></label> */}
                                            <div className="mb-3 files2 ">
                                                <div className="mb-3 files">
                                                    <input 
                                                        disabled={loading} 
                                                        className="form-control" 
                                                        id="formFileSm" 
                                                        type="file" 
                                                        name="csv" 
                                                        onChange={handlecsv} 
                                                        accept=".ttb,.pdl" 
                                                        required
                                                        multiple
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </Formik>
                                {
                                    loading &&
                                    <div className='percents'>                                    
                                        <div>
                                            <p>{`${_loaded}kb of ${_total}kb | ${_percent}%`}</p>
                                        </div>
                                        <div className="progress">
                                            <div className={bar}
                                                role="progressbar" 
                                                aria-label="Animated striped" 
                                                aria-valuenow={`${_percent}`}
                                                aria-valuemin="0" 
                                                aria-valuemax="100" 
                                                style={{width: `${_percent}%`}}
                                            ></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>				
            </div>
        </div>
    )
}

export default Templates