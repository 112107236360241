import React, {useContext} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2'
import { NavLink } from "react-router-dom";
/*Globales*/
import UserContext from '../../userContext/UserContext';
import { url } from '../../global/Global';
import { useNavigate } from "react-router-dom";

const Header = () => {
	const navigate = useNavigate();
	const { user, seleccionarUser } = useContext(UserContext);

	const logout = async (e) => {
	    e.preventDefault();
	    // console.log(user);

	    Swal.fire({
			title: 'Are you sure to Logout?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, Logout',
			cancelButtonText: 'Cancel'
	    }).then(async (result) => {
			if (result.isConfirmed) {
				seleccionarUser(null);
				await AsyncStorage.removeItem(
					'@user'
				);
				return navigate("/");
			}
		});				
	}	
	return (
	<>
		<nav className="navbar navbar-expand-lg navbar-dark bg-primary-300 sticky-top mb-3">
			<div className="container">
				<NavLink className="navbar-brand text-uppercase" to="/">Templates</NavLink>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded='false' aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className={`collapse navbar-collapse`} id="navbarNav">
					<ul className="navbar-nav me-auto">
						<div className="d-none">
						</div>
					</ul>
					<ul className="navbar-nav ml-auto">
						{
							user !== null &&
							<> 
								<li className="nav-item">
								<a href="/#" className="nav-link" 
									onClick={(e) => logout(e)}
									>
									Logout</a>
								</li>
							</> 
						}
					</ul>
				</div>
			</div> 
		</nav>
	</>
	)
}
export default Header;