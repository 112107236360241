import React, {useState, useEffect} from 'react'
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from 'axios';
import { url } from '../../global/Global';
import {
	NavLink,
} from "react-router-dom";
const Softwares = () => {

    const [softwares, setsoftwares] = useState([]);

	const first = async() => { 			
		// const log_json = await JSON.parse(user);
		let   log_json = await AsyncStorage.getItem('@user');
		const token    = `Bearer ${log_json.replace(/^["'](.+(?=["']$))["']$/, '$1')}`;

		const headers = {
			'Authorization': token,
			'Content-Type': 'application/json',
			'X-Requested-With': 'XMLHttpRequest',
		}
        console.log(`${url}softwares`);
        console.log(headers);
		// await axios.get(url+'dirs', {headers: headers})
		await axios.get(`${url}softwares`, {headers: headers})
			.then(async (response) => {
				console.log(response.data);
				if(response.data.code === 200 && response.data.status === 'success'){
					setsoftwares(response.data.message);
				}else{
					alert('vacio')
				}
			}).catch(err =>{
				console.log('err');
				console.log(err);
			});
	}
	
	useEffect(() => {
		first();
	}, [])

  return (
    <div className='bg-light h-100 vh-100'>
			<div className='container bg-light py-5'>
				<div className="row">
                    <div className='col-md-12 mb-3'> 
                        <div className="card h-100 animate__animated animate__fadeIn">
                            <div className="card-header text-center">
                                <h3 className='fw-bold'>
                                    Select templates
                                </h3>
                            </div>
                            <div className='card-body'>                                
                                <div className="row">
                                {
                                    softwares.length > 0 &&
                                        softwares.map((e,i) => {
                                            return <div className="col-md-6" key={i}>
                                                <NavLink className='nav-link' to={`/directories/${e.name}`}>
                                                    <div className="card">
                                                        <div className='d-flex justify-content-center'>
                                                            <img src={`${url}images/${e.image}`} className="card-img-top" alt={`${e.name}`}  style={{width: 100}}/>
                                                        </div>
                                                        <div className="card-body">
                                                            <p className="card-text text-center fw-bold text-uppercase">{e.name}</p>
                                                        </div>
                                                    </div>
                                                </NavLink> 
                                            </div>
                                        })
                                }					
                                </div>
                            </div>
                        </div>
                    </div>
				</div>				
			</div>
		</div>
  )
}

export default Softwares