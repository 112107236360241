import React, {useContext, useEffect} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import UserContext from '../../userContext/UserContext';
import { url } from '../../global/Global';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

const Login = () => {

	const { seleccionarUser } = useContext(UserContext);
	const date                = new Date();
	const navigate            = useNavigate();

/**********************************************************************************************************************/
	useEffect(() => {
		const first = async() => {
            let _user    = await AsyncStorage.getItem('@user');

            if(_user !== null && _user !== 'null' && _user !== undefined && _user !== 'undefined'){
                return navigate("/directories");
            }else{
                return navigate("/login");
            }
        }
        first();
	// eslint-disable-next-line
	}, []);
	
/**********************************************************************************************************************/
	const sendUser = async (params) => { 
		// console.log(params);
		// console.log(url);
		const headers = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(url+'users/login', params, {headers: headers})
            .then(async (response) => {
				console.log(response.data);
				if(response.data.code === 200 && response.data.status === 'sucess'){
					await AsyncStorage.setItem(
						'@user',
						JSON.stringify(response.data.token)
					);
					seleccionarUser(response.data.token);
					return navigate('/directories')
				}else if(response.data.code === 200 && response.data.status === 'warning'){
					Swal.fire({
						icon: 'info',
						title: 'Error!',
						text: response.data.message,
					});
				}else{
					console.log(`Ocurrio un error else --- ${response.data}`)
				}
			}).catch(err =>{
				console.log(`Ocurrio un error catch --- ${err}`);
			});
		
	
	}
/**********************************************************************************************************************/
	//Validador de datos
    const formSchema = Yup.object().shape({
        email: Yup.string()
            .email()
            .required(),
        password: Yup.string()
            .required(),
    });
/**********************************************************************************************************************/
	return (
		<main className="text-center bady">
			<div className="form-signin w-100 m-auto animate__animated animate__fadeIn">
				<Formik
					initialValues={{ 
						email: '', 
						password: '' 
					}}
					validationSchema={formSchema}
                	onSubmit={(values, actions) => sendUser(values, actions)}
            	>
					<Form>
						<img className="mb-4" src="https://labeldictate.com/assets/img/Label-Dictate-Logo-V2021.png" alt="" width={200} />
						<h1 className="h3 mb-3 fw-normal">Please sign in</h1>
						
						<div className="form-floating">
							<Field type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="email"/>
							<label htmlFor="floatingInput">Email address</label>
						</div>
						<ErrorMessage name="email" component="div" className='field-error text-danger'/>

						<div className="form-floating">
							<Field type="password" className="form-control" id="floatingPassword" placeholder="Password" name="password"/>
							<label htmlFor="floatingPassword">Password</label>
						</div>
						<ErrorMessage name="password" component="div" className='field-error text-danger'/>

						<button className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>

						<p className="mt-5 mb-3 text-muted">&copy; 2022-{date.getFullYear()}</p>
					</Form>
				</Formik>
			</div>
		</main>
	)
}


export default Login