import React, {useState, useEffect, useRef} from 'react'
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from 'axios';
import { url } from '../../global/Global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faFolderPlus, faTrashAlt, faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import {
	NavLink,
	useParams,
	useNavigate
} from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

const Dir = () => {

	const [dirs, setdirs] = useState({});
	const { name }        = useParams();
	let   formDirectory   = useRef(null);
	const navigate        = useNavigate();

	const [verifyStatus, setverifyStatus]   = useState(false);
	const [verifyMessage, setverifyMessage] = useState('');
	const [loading, setloading]             = useState(false);
/********************************************************************************************************************/
	const first = async() => { 			
		// const log_json = await JSON.parse(user);
		let   log_json = await AsyncStorage.getItem('@user');
		const token    = `Bearer ${log_json.replace(/^["'](.+(?=["']$))["']$/, '$1')}`;

		const headers = {
			'Authorization': token,
			'Content-Type': 'application/json',
			'X-Requested-With': 'XMLHttpRequest',
			'Access-Control-Allow-Origin': '*'
		}

		// await axios.get(url+'dirs', {headers: headers})
		await axios.get(`${url}dirs/${name}`, {headers: headers})
			.then(async (response) => {
				console.log(response.data);
				if(response.data.code === 200 && response.data.status === 'success'){
					setdirs(response.data.message);
					console.log(response.data.message);
				}else if(response.data.code === 200 && response.data.status === 'warning'){
					setverifyStatus(true);
					setverifyMessage(response.data.message);

				}else{
					Swal.fire({
						icon : 'error',
						title: 'Error!',
						text : response.data.message,
					});
				}
			}).catch(err =>{
				console.log('err');
				console.log(err);
			}).then(()=>{
				setloading(true)
			});
	}
/********************************************************************************************************************/
	useEffect(() => {
		first();
	}, [])
/********************************************************************************************************************/

	const createDirectory = async(params, actions) => { 
		params.software = name;

		let   log_json = await AsyncStorage.getItem('@user');
		const token    = `Bearer ${log_json.replace(/^["'](.+(?=["']$))["']$/, '$1')}`;

		const headers = {
			'Authorization': token,
			'Content-Type': 'application/json',
			'X-Requested-With': 'XMLHttpRequest',
			'Access-Control-Allow-Origin': '*'
		}

		await axios.post(`${url}dirs`, params, {headers: headers})
			.then(async (response) => {
				console.log(response.data);
				if(response.data.code === 200 && response.data.status === 'success'){
					setdirs(response.data.message);
					Swal.fire({
                        icon : 'success',
                        title: 'Success',
                        text : 'Directory created',
                    });
					console.log(response.data.message);
				}else if(response.data.code === 400 && response.data.status === 'error'){
						Swal.fire({
							icon : 'error',
							title: 'Error!',
							text : response.data.message,
						});
				}else{
					Swal.fire({
						icon : 'error',
						title: 'Error!',
						text : response.data.message,
					});
				}
			}).catch(err =>{
				console.log('err');
				if(err?.ecode == 11000){
					Swal.fire({
						icon : 'error',
						title: 'Error!',
						text : 'Duplicate name in directory',
					});
				}else{
					Swal.fire({
						icon : 'error',
						title: 'Error!',
						text : err,
					});
				}
			});

		console.log(params);
		formDirectory.current.click();
		actions.resetForm();
	}
/********************************************************************************************************************/
	//Validador de datos
	const formSchema = Yup.object().shape({
		directory: Yup.string()
			.required()
			.matches(/^[a-z 0-9]+$/i, 'Only Alphanumeric Characters')
	});
/********************************************************************************************************************/

	const deleteDirectory = (params) => { 
		console.log(params);
		console.log(`${url}${params}/${name}`);
		Swal.fire({
            title: 'Delete dir & templates?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
        if (result.isConfirmed) {

			const { value: dirname } = await Swal.fire({
				title: "Write directory name to delete",
				html: `<span>Directory name: </span> '<b>${params}</b>'`,
				input: 'text',
				inputPlaceholder: 'Directory to delete',
				showCloseButton: true,
				inputValidator: (value) => {
					console.log(value);
					if (value.toLowerCase() !== params.toLowerCase()) {
					  return 'Wrong directory!'
					}
				},
				confirmButtonColor: '#dc3545',
				confirmButtonText: 'Delete'
			  })
			  
			if (!dirname) return;
			
            let   log_json = await AsyncStorage.getItem('@user');
            const token    = `Bearer ${log_json.replace(/^["'](.+(?=["']$))["']$/, '$1')}`;

            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Access-Control-Allow-Origin': '*'
            }

            await axios.delete(`${url}dirs/${params}/${name}`, {headers: headers})
                .then(async (response) => {
                    console.log(response.data);
                    if(response.data.code === 200 && response.data.status === 'success'){
                        console.log(response.data.message);
                        setdirs(response.data.message);
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: response.data.message,
                        });
                    }
                }).catch(err =>{
                    console.log('err');
                    console.log(err);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: err,
                    });
                });                
            }
        });
        
    }

/********************************************************************************************************************/
	return (
		<>
		{
			!loading 
			?
			<div className='container text-center vh-100'>
				<div className='d-flex flex-column align-items-center justify-content-center h-100 animate__animated animate__fadeIn'>
					<div className="spinner-grow" style={{width: "5rem", height: "5rem", marginBottom: "6rem", color: "#6ea8fe"}} role="status">
						<span className="visually-hidden">Loading...</span>
					</div>

				</div>
			</div>
			:<>
			{/* Modal */}
			{/* <!-- Button trigger modal --> */}
				<button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={formDirectory}></button>
				<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
					<div className="modal-dialog modal-sm">
						<div className="modal-content">
							<div className="modal-header">
								<h1 className="modal-title fs-5" id="staticBackdropLabel">New Directory (Folder)</h1>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<Formik
								initialValues={{ 
									directory: ''
								}}
								validationSchema={formSchema}
								onSubmit={(values, actions) => createDirectory(values, actions)}
							>
								<Form>
									<div className="modal-body">
										<div className="form-floating">
											<Field type="text" className="form-control" id="floatingInput" placeholder="New Directory" name="directory"/>
											<label htmlFor="floatingInput">Directory's name</label>
										</div>
										<ErrorMessage name="directory" component="div" className='field-error text-danger'/>									
									</div>
									<div className="modal-footer">
										<button type="submit" className="btn btn-primary">Save directory</button>
									</div>
								</Form>
							</Formik>
						</div>
					</div>
				</div>
			{/* Modal End */}

			{
			verifyStatus 
			?<>
				<div className='w-100 border-top border-bottom px-5 alert alert-primary animate__animated animate__fadeIn'>
					<div className='row'>
						<div className='col-md-2'>
							<div className="d-flex px-2 align-items-center pointer-f pointer-f-directory rounded " onClick={() => navigate(-1)}>
								<FontAwesomeIcon icon={faCircleLeft}/>
								<p className='ms-2 m-0'>Go back</p>
							</div>
						</div>
						<div className="col-md-7"></div>
					</div>
				</div>
				<div className='container mt-5 text-center'>
					<h2>{verifyMessage}</h2>
				</div>
			</>

			:<div className='bg-light h-100 vh-100'>
				<div className='w-100 border-top border-bottom px-5 alert alert-primary animate__animated animate__fadeIn'>
					<div className='row '>
						<div className='col-md-2'>
							<div className="d-flex px-2 align-items-center pointer-f pointer-f-directory rounded" onClick={() => navigate(-1)}>
								<FontAwesomeIcon icon={faCircleLeft}/>
								<p className='ms-2 m-0'>Go back</p>
							</div>
						</div>
						<div className="col-md-7"></div>
						<div className='col-md-3'>
							<div className="d-flex px-2 align-items-center pointer-f pointer-f-directory rounded" onClick={() => formDirectory.current.click()}>
								<FontAwesomeIcon icon={faFolderPlus}/>
								<p className='ms-2 m-0'>Create new directory</p>
							</div>
						</div>
					</div>
				</div>
				<div className='container bg-light py-3 animate__animated animate__fadeIn'>
					<div className="row">
						{
							Object.keys(dirs).length > 0 &&
								Object.keys(dirs).map((e,i) =>{
									return <div className='col-md-4 mb-3' key={i}> 
										<div className="card h-100">
										<div className="card-header">
											<div className="row">
												<div className="col-12 text-end">
													<button className='btn btn-danger btn-sm rounded-circle' onClick={() => deleteDirectory(e)}> 
														<FontAwesomeIcon icon={faTrashAlt} color='white'/>
													</button>
												</div>
											</div>
										</div>
										<NavLink className='nav-link' to={`/directories/${name}/${e}`}>
											<div className="row h-100 g-0">
												<div className="col-md-4">
													<div className='row align-items-left h-100'>
														<div className='w-100 text-center m-2'>
															{/* <FontAwesomeIcon icon="far fa-folder" /> */}
															<FontAwesomeIcon icon={faFolderOpen} size="5x" color='#ffe38f' />
														</div>
													</div>
												</div>
												<div className="col-md-8">
													<div className="card-body">
													<h5 className="card-title fw-bold text-capitalize">{e}</h5>
													<ul>
													{
														dirs[e].map((el, j) =>{
															return <li  key={j}>{el.name}</li>
														})
													}
													</ul>
													{/* <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p> */}
													</div>
												</div>
											</div>
										</NavLink>
										</div>
									</div>
								})
						}					
					</div>				
				</div>
			</div>
			}
		</>
		}
	</>
	)
}

export default Dir