import React, { useReducer, useEffect } from 'react';
import UserReducer from './UserReducer';
import UserContext from './UserContext';
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from 'axios';
import { url } from '../global/Global';
import { useNavigate } from "react-router-dom";
const UserState = (props) => {

    let navigate = useNavigate();

    useEffect(() => {
        const firstState = async() => {         
            let _user    = await AsyncStorage.getItem('@user');
            if(_user !== null && _user !== 'null' && _user !== undefined && _user !== 'undefined'){
                const log_json    = `Bearer ${_user.replace(/^["'](.+(?=["']$))["']$/, '$1')}`;
                console.log(log_json)
                const headers = {
                    'Authorization': log_json,
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                }

                await axios.get(url+'users/validate', {headers: headers})
                    .then(async (response) =>  { 
                        console.log(response);
                        seleccionarUser(_user);
                    })
                    .catch(async(error) => {
                        console.log(error);
                        seleccionarUser(null);
                        await AsyncStorage.removeItem(
                            '@user'
                        );
                        return navigate("/login");
                    });                
            }else{
                return navigate("/login");
            }
        }
        firstState();

    }, []);
        
    const initialState = {
        user: null
    }
    // useReducer con dispatch  para ejecutar las funciones
    const [ state, dispatch ] = useReducer(UserReducer, initialState);

    // Selecciona el Producto que el usuario desea ordenar
    const seleccionarUser = (user) => {
        dispatch({
            type: 'SELECCIONAR_USER',
            payload: user
        })
    } 

    return (
        <UserContext.Provider
            value={{
                user    : state.user,
                seleccionarUser,
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export default UserState;