
export default (state,action) => {
    switch (action.type) {
        case 'SELECCIONAR_USER':
            return {
                ...state,
                user: action.payload,
            }
        default:
            return state;
    }
}